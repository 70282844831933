.product-category-grid {
    font-size: 12px;
}

.product-category-grid th {
    padding: 8px;
    border: none !important;
    font-size: 14px;
    font-weight: 600;
    color: #1a71ef;
}

.product-category-grid td {
    padding: 8px;
    border: none !important;
}
tbody tr {
    border-bottom: 1px solid #F7F9FA !important;
    border-radius: 4px;
}
.product-category-grid tr:hover {
    background-color:  #F7F9FA;
}

.product-title {
    color: rgba(51, 51, 51, 0.85);
    font-weight: 500;
    font-size: 1.25rem;
}

.edit-btn {
    height: 1.5rem;
}

.product-form label {
    width: 10rem;
}

.product-form .p-dropdown,
.product-form .p-inputtext {
    width: 15rem;
    height: 2.25rem;
}

.product-form input {
    font-size: 12px;
    padding: 4px;
}

.product-form .form-error {
    font-size: 10px;
    color: red;
}

.product-form .form-btns button {
    border-radius: 4px;
    padding: 2px 8px;
}

.product-actions-btns .p-dialog-header-close {
    margin: 0.5rem;
}


#ProductCategory .p-datatable-wrapper {
    height: calc(100vh - 205px);
    border-radius: 6px;
}

#gridTable .p-datatable-wrapper {
    height: calc(100vh - 178px);
    border-radius: 6px;
}

.p-paginator-bottom {
       padding: 0px;
    
}
#fuel-lines-grid .p-datatable-wrapper {
    height: calc(100vh - 325px);
    border-radius: 6px;
}

#fuelLinesReadingTabGrid .p-datatable-wrapper {
    height: calc(100vh - 330px);
    border-radius: 6px;
}

#fuelSystemLinesGrid .p-datatable-wrapper {
    height: calc(100vh - 345px);
    border-radius: 6px;
}

.section_bg {
    background-color: var(--main-component-bg);
}

.primary_section_bg {
    height: calc(100vh - 90px) !important;
    overflow: auto;
}

.pagination-controls {
    bottom: 0px;
    position: sticky;
    background: var(--grid_header_bg);
    padding: 7px 0px;
    border-top: 1px solid #d4dcea;
    font-size: var(--secondary-fs);
    color: var(--layout_text_color);
}

.pagination-controls button {
    background: transparent;
    border: 1px solid #d4dcea;
    padding: 2px 10px;
    border-radius: 4px;
    margin: 0 10px;
    font-size: var(--secondary-fs);
    color: var(--layout_text_color);
}
.p-paginator .p-paginator-current{
    font-size: 13px;
    height: auto !important;

}
.p-paginator {
    border: 1px solid #d4dcea;
    border-width: 0;
}

.p-paginator {
    border: 1px solid #d4dcea;
    border-width: 1px;
}

.p-paginator svg {
    height: 20px;
    width: 30px;
}

.p-paginator .p-dropdown,
.p-paginator button {
    /* height: var(--input-height) !important; */
    min-height: 1rem !important;
    padding: 3px 5px;
    align-items: center;
    min-width: 0.5rem !important;
    border: 1px solid var(--border-color);
    border-left: 0;
    height: 20px !important;
}

.p-paginator .p-paginator-first {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-left: 1px solid var(--border-color) !important;
}

.p-paginator .p-paginator-last {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.p-paginator-pages {
    margin-top: -1px;
}
.showDropDown{
    padding-top: 4px;
}
.showDropDown span{
    vertical-align: text-top;
}
.p-paginator-element.p-link:hover {
    background: transparent;
}

.p-paginator .p-dropdown {
    background-color: transparent;
    border: 1px solid var(--border-color);
    margin-right: auto;
    margin-left: 10px;
}

.p-paginator .p-inputtext {
    font-size: var(--secondary-fs);
}

.p-paginator .p-highlight {
    background: transparent;
    color: var(--page_selection_color);
    font-weight: 700 !important;
}

button.p-paginator-page.p-paginator-element.p-link {
    width: auto;
    min-width: 20px;
    font-size: var(--secondary-fs);
  
}

.nav_sec_2 img {
    width: 17px;
    filter: var(--navbar_icons_color);
}

.nav_sec_2 img:hover {
    filter: var(--navbar_icons_hover_color);
}

input::placeholder {
    font-size: var(--secondary-fs);
    color: var(--placeholder-color);
}

.border-secondary-subtle {
    border-color: var(--border-color) !important;
}

label {
    font-size: var(--primary-fs);
    color: var(--label_text_color);
    font-weight: 400;
}

.p-dropdown.p-component.p-inputwrapper {
    background: transparent;
    border: 1px solid #d4dcea !important;
}

.btn-button {
    background-color: #63d395;
    color: white;
    border-radius: 5px;
    height: 30px;
    text-align: center;
    border-color: white;

}

/* .grid_buttons button {
    background-color: #63d395;
    color: white;
    border-radius: 5px;
    height: 30px;
    text-align: center;
    border-color: white;
} */

.field-checkbox {
    height: 25px;
    width: 25px;
    margin: 0px;
}

#site_name_data_table .p-datatable-wrapper {
    min-height: 500px !important;
}

.user-popup {
    width: 30vw;
}

.section_bg {
    background-color: var(--main-component-bg);
}

.primary_section_bg {
    height: calc(100vh - 90px) !important;
    overflow: auto;
}

/* .prods:not(:last-child),
.grid_assets:not(:last-child) {
    border-bottom: 1px solid #D4DCEA !important;
} */

td:has(.prods),
td:has(.grid_assets) {
    padding: 0 !important;
}

.prods,
.grid_assets {
    padding: 3px 8px;
}

.p-multiselect-token {
    font-size: 11px;
    padding: 1px 8px;
}

.p-multiselect-items .p-component {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;

}

.p-multiselect-items-wrapper {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: start;
}

#state-dropdown {
    display: flex;
    flex-direction: column;
    align-items: start;
}
thead{
   max-width: 42px;
   height: 42px; 
}
tbody tr td{
    color: #24272A;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
}

.artable-sort-arrow{
    fill: #A4A8B1;
  }
  .sort-asc #up-arrow, .sort-desc #down-arrow {
    fill: #4338ca;
  }

  .p-column-header-content{
    line-height: 1px;
  }
  #custom-sort-icon{
    width: 18px;
    height: 18px;
  }
  .p-paginator-bottom{
    justify-content: flex-end;
    height: 40px;
  }
.p-paginator-pages .p-highlight{
    border-radius: 4px;
    background-color: #F0F2F4 ;
    color: black;
}
.p-checkbox .p-checkbox-box{
    width: 16px;
    height: 16px;
    color: #4b5563;
    border-radius: 4px;
    margin-top: 4px;
}
.mt-3{
    margin-top: 0.5rem !important;
}
.p-chart{
    height: 67vh;
}
.p-cancel{
    border-radius: 5px;
    height: 100%;
    margin: 14px;
    border-color: white;
    background-color:#d9534f;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
}
.p-save{
    border-radius: 5px;
    height: 100%;
    margin: 14px;
    border-color: white;
    background-color:#337ab7;   
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
}

.newlyAddedRecord {
    background-color: #ccc !important;
}
.AccessDenied{
    align-items: center;
    background: #ecf2fe;
    border: 1px solid #85a9ef;
    border-radius: 4px;
    color: #07183a;
    display: flex;
    flex-direction: row;
    /* font-family: Roboto; */
    font-size: 14px;
    font-weight: 400;
    padding: 24px;
    width: 97%;
    margin: 20px 24px 0;
}

.error-page {
    background-color: #fff;
    border: 1px solid #eaeeef;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 #578ef74d;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    max-height: 665px;
    padding: 28px;
    margin-top: 8px;
    margin-right: 10px;
}
.error-page h1 {
    color: #3a4669;
    font-family: Roboto;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 2px;
    line-height: normal;
    text-align: center;
}
.error-page svg {
    height: auto;
    margin: auto;
    width: 80%;
}
.error-page .protean-btn {
    display: flex;
    justify-self: center;
    margin: 20px auto 0;
    width: fit-content;
}